@import "~vgg-ui-react/dist/styles/partials";
@import "../../../../assets/styles/common/colors.scss";
@import "../../../../assets/styles/common/mixins.scss";

.view-payout-modal-container{
  .title {
    @include text(600, 20px, 16px, $pure-black);
  }
  .item-head {
    border-bottom: 1px solid $gray-100;
  }
  .item-title {
    margin-top: 20px;
    @include text(600, 12px, 16px, $spn-500-color);
  }
  .item-value {
    margin: 16px 0 0px 0;
    padding-bottom: 20px;
    border-bottom: 1px solid $spn-050-color;
    @include text(400, 16px, 16px, $spb-900-color);
}
}
