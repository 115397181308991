@import "../../assets/styles/common/colors.scss";

.modal__body {
  padding: 20px 16px 60px 16px !important;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 70vh;

  .success__icon,
  .error__icon {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    color: $pure-white;
  }

  .success__icon {
    background-color: $spg-500-color;
  }

  .error__icon {
    background-color: $spr-500-color;
  }

  .notice {
    color: $spn-500-color;
    text-align: center;
    font-size: 1rem;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.4px;
  }

  .msg-wrapper {
    flex: 1;
    font-size: 1.5rem;
  }
}

.main__modal {
  max-width: 680px;
}

.gap-4 {
  gap: 4rem;
}