@import "~vgg-ui-react/dist/styles/partials";
@import "../../assets/styles/common/colors.scss";
@import "../../assets/styles/common/mixins.scss";

.onboard-wrapper {
  .alert-icon-wrapper {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.4px;
  }

  .f-11 {
    flex: 11;
  }

  .learn-more {
    background: $spn-000-color;
    border: 1px solid $gray-100;
    letter-spacing: 0.4px;
    @include text(500, 14px, 16px, $gray-600);
  }

  .head-section {
    &__icon {
      @include text(500, 14px, 16px, $primary-color);
      background: $gray-065;
      border-radius: 6px;
      padding: 10px;
      cursor: pointer;
      transition: opacity 0.3s;

      i {
        font-size: 25px;
      }

      &:hover {
        opacity: 0.8;
      }
    }

    &__title {
      .pg-title {
        font-weight: $sp-fontweight--600;
      }

      .pg-subtitle {
        font-weight: $sp-fontweight--600;
      }
    }
  }

  .card-section {
    background: $gray-051;
    border: 1px solid $gray-100;
    border-radius: 4px;

    .content-paragraph {
      letter-spacing: -0.048px;
      @include text(400, 16px, 24px, $dark-100);
    }

    .footnote {
      @include text(500, 14px, 16px, $dark-200);
      letter-spacing: 0.4px;
    }
  }
}