@import "../../assets/styles/common/colors.scss";

.summary {
  display: flex;
  border: 1px solid $gray-100;
  padding: 16px;
  height: 120px;

  .users {
    flex: 1;
    border-right: 1px solid $gray-100;
    padding: 0 16px;

    .icon {
      border-radius: 50%;
      color: $primary-color;
      width: 32px;
      height: 32px;
      background: $blue-900;
    }
  }

  .currency {
    flex: 1;
    padding: 0 16px;

    .icon {
      border-radius: 50%;
      color: $primary-color;
      width: 32px;
      height: 32px;
      background: $blue-900;
    }
  }
}

.payout-reports {
  width: 676px;
}

.top-position {

  position: absolute;
  top: 0;
  right: 0;
}

.DateRangePicker {
  &__CalendarSelection {
    background-color: $primary-color;
    border: 1px solid $spb-600-color;
  }

  &__Date {
    &--is-highlighted {
      &:hover {
        // background-color: black;
        border-color: $blue-400;
      }
    }
  }
}

@media only screen and (max-width: 680px) {
  .payout-reports {
    width: 100%;
  }
}