.toggle-btn {
  background: #f4f5f6 !important;
  border: 1px solid #d7dce0 !important;
  border-radius: 4px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  display: flex !important;
  align-items: center !important;
  letter-spacing: -0.05px !important;
  color: #677684 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  display: flex !important;
  align-items: center !important;
  padding: 11px !important;
  transition: all 0.4s;

  &:focus {
    box-shadow: none !important;
  }
  &:hover {
    background: #e9e9ea !important;
  }
}
