@import "../../../assets/styles/common/colors.scss";
@import "../../../assets/styles/common/mixins.scss";

.h-36 {
  height: 36vh;
}

.wrap-nodata {
  height: 40vh;
  width: 100%;
}

.currencies {
  font-size: 12px !important;
  color: $spn-400-color !important;
  font-weight: 500 !important;
  line-height: 16px !important;
  background-color: transparent !important;
  cursor: pointer;

  &.active,
  &:hover {
    color: $primary-color !important;
    background-color: $blue-900 !important;
  }
}
