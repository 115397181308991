@import "../../../assets/styles/common/colors.scss";
@import "../../../assets/styles/common/mixins.scss";


.transaction-wrapper {
  height: 100%;
  border: 1px solid $gray-100;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 0;

  .title-text {
    padding: 1rem;
    border-bottom: 1px solid $gray-100;
  }

  .more-options{
    position: absolute;
    background: $white;
    border: 1px solid $gray-100;
    border-radius: 4px;
    z-index: 100000;
    width: 150px;
    text-align: left;
    margin-left: -30px;
    @include text(400, 14px, 16px, $spb-900-color);
    .option{
      padding: 8px 16px;
    }
    .option::nth-child(even){
      background-color: $spn-050-color;
    }
  }
  .view-payout-detail, .option {
    cursor: pointer;
    text-align: center;
    @include text(500, 14px, 16px, $primary-color);
  }

  .search-wrapper {
    padding: 1rem;
    div {
      width: 300px;
      position: relative;
      i {
        position: absolute;
        top: 25%;
        right: 12px;
        color: #24292e;
      }
    }
  }

  .success,
  .fail {
    height: 6px;
    width: 6px;
    border-radius: 50%;
  }
  .success {
    background: $spg-700-color;
  }

  .fail {
    background: #941e1e;
  }
}

.DateRangePicker {
  &__CalendarSelection {
    background-color: #175fff;
    border: 1px solid #0654fe;
  }
  &__Date {
    &--is-highlighted {
      &:hover {
        // background-color: black;
        border-color: green;
      }
    }
  }
}

@media only screen and (max-width: 680px) {
  .payout-reports {
    width: 100%;
  }
}
