.card-wrapper {
  transition: all 0.2s;
  cursor: pointer;
  &:hover {
    background-color: #f4f5f6;
  }

  .arrow {
    color: #0c5449;
    background-color: #f6f3ed;
    margin: 1em 0;

    &::after {
      display: inline-block;
      -webkit-transition: transform 0.3s ease-out;
      -moz-transition: transform 0.3s ease-out;
      -ms-transition: transform 0.3s ease-out;
      -o-transition: transform 0.3s ease-out;
      transition: transform 0.3s ease-out;
    }

    &:hover {
      background-color: #f6f3ed;

      &::after {
        -webkit-transform: translateX(4px);
        -moz-transform: translateX(4px);
        -ms-transform: translateX(4px);
        -o-transform: translateX(4px);
        transform: translateX(4px);
      }
    }
  }
}
