.payout-modal {
  &__footer {
    margin-block: 20px;
  }

  .pointer {
    cursor: pointer;
    color: #1354d3;
  }

  .modal-header {
    align-items: flex-start;
  }

  .modal-content {
    border-radius: 1rem;
  }

  .sub-head {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4000000059604645px;
    text-align: left;
    color: #677684;
  }
}
